<template>
  <a-card :style="css" :bordered="false">
    <a-tabs default-active-key="1" @change="tabChange">
      <a-tab-pane key="1" tab=" 登录背景设置">
        <a-form-model ref="form" style="position: relative; padding-top: 20px" :model="formData" v-bind="layout">
          <a-form-model-item label="主题颜色" prop="color">
            <!-- <a-input v-model="color" placeholder="请输入主题颜色"></a-input> -->
            <colorPicker v-model="color" @change="addColor" />
            <!-- <a-button type="text" style="margin-left:5px;z-index:0" icon="plus" @click="addColor">添加</a-button> -->
          </a-form-model-item>
          <div style="margin-left: 80px; margin-bottom: 5px; margin-top: -10px; width: 650px">
            <a-tag
              :color="color.value"
              style="margin-bottom: 10px"
              closable
              @close.prevent.stop="handleClose(index)"
              v-for="(color, index) in formData.colors"
              :key="index"
              >{{ color.value }}</a-tag
            >
          </div>
          <a-form-model-item label="遮罩颜色" prop="backColor">
            <colorPicker v-model="formData.background" />
            <a-slider id="test" style="width: 200px; margin-left: 20px" v-model="formData.opacity" />
          </a-form-model-item>
          <a-form-model-item label="背景图片" prop="color">
            <a-upload
              v-if="!formData.userFile.avatar"
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :beforeUpload="beforeCroUpload"
              @change="handleUpChange"
            >
              <div
                v-if="formData.userFile.avatar"
                :src="formData.userFile.avatar"
                :style="
                  imgStyle +
                    'background: url(' +
                    formData.userFile.avatar +
                    ') bottom / cover no-repeat;position: relative;'
                "
                alt="avatar"
              >
                <div
                  class="opcity-div"
                  :style="imgStyle + 'opacity:' + formData.opacity / 100 + ';background-color:' + formData.background"
                />
              </div>
              <div v-else>
                <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <a-upload
              v-else
              name="file"
              class="avatar-uploader"
              :show-upload-list="false"
              :beforeUpload="beforeCroUpload"
              @change="handleUpChange"
            >
              <a-button style="z-index:0"> <a-icon type="upload" /> 上传图片 </a-button>
            </a-upload>
            <div class="cropper-content" v-if="formData.userFile.avatar">
              <div class="cropper">
                <vue-cropper
                  ref="cropper"
                  mode="100% auto"
                  :fixed="true"
                  :centerBox="true"
                  :img="option.img"
                  :outputSize="option.size"
                  :outputType="option.outputType"
                  :info="true"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :fixedNumber="[16, 9]"
                  @realTime="realTime"
                ></vue-cropper>
              </div>

              <div
                class="show-preview"
                :style="{ width: previews.w + 'px', height: previews.h + 'px', overflow: 'hidden', margin: '5px' }"
              >
                <div
                  class="opcity-div"
                  :style="imgStyle + 'opacity:' + formData.opacity / 100 + ';background-color:' + formData.background"
                />
                <div :style="{ ...previews.div }" class="preview">
                  <img :src="previews.url" :style="previews.img" />
                </div>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="logo" prop="color">
            <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="ossUploadUrl"
              :data="ossUploadData"
              :beforeUpload="beforeUpload"
              @change="
                info => {
                  handleChangeUpload(info, 'LOGO')
                }
              "
            >
              <img v-if="formData.logo" :src="formData.logo" style="width: 200px" alt="avatar" />
              <div v-else>
                <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="slogan" prop="color">
            <a-input v-model="formData.slogan" placeholder="请输入slogan"></a-input>
          </a-form-model-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 0 }">
            <a-button icon="save" style="margin-left: 80px" type="primary" @click="save">保存</a-button>
          </a-form-item>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="2" tab=" 挂件管理">
        <pendant />
      </a-tab-pane>
      <a-tab-pane key="3" tab=" 首页顶部背景管理">
        <default-size-upload />
      </a-tab-pane>
    </a-tabs>

    <cropper-model ref="cropperModel" @upImg="upImg"></cropper-model>
  </a-card>
</template>

<script>
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import { getAction, putAction } from '@/api/manage'
import { VueCropper } from 'vue-cropper'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import cropperModel from '@/components/cropper/index.vue'
import pendant from '@/components/pendant/index.vue'
import defaultSizeUpload from '@/components/default-size-upload'
import axios from 'axios'
export default {
  name: 'BackGround',
  mixins: [UploadFileToOssMixins],
  components: {
    cropperModel,
    VueCropper,
    pendant,
    defaultSizeUpload
  },
  data() {
    return {
      imgStyle:
        'width:' /* + document.body.clientWidth / 3 */ +
        '640px;' +
        'height:' /* + document.body.clientHeight / 3 */ +
        '360px;',
      formData: {
        colors: [],
        userFile: {
          avatar: ''
        },
        logo: '',
        slogan: '',
        opacity: 0,
        background: '#000000'
      },
      color: '#000000',
      layout: {
        labelCol: { style: 'width: 80px;', span: 4 },
        wrapperCol: { span: 12 }
      },
      avatarLoading: false,
      fileList: [],
      open: false,
      imageUrl: '',
      crap: false,
      previews: {
        url: ''
      },
      option: {
        img: '',
        size: 1,
        full: true, //输出原图比例截图 props名ful
        outputType: 'png',
        canMove: true,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 400,
        autoCropHeight: 225,
        fixedBox: false
      },
      downImg: '#',
      fileName: ''
    }
  },
  computed: {
    css() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  created() {
    this.getBackGround()
  },
  mounted() {},
  methods: {
    tabChange(key) {
      this.tab = key
    },
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    finish(type) {
      var test = window.open('about:blank')
      test.document.body.innerHTML = '图片生成中..'
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob(data => {
          var img = window.URL.createObjectURL(data)
          this.model = true
          this.modelSrc = img
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          this.model = true
          this.modelSrc = data
        })
      }
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data
    },
    openModal(url) {
      this.option.img = url
      this.fileName = this.getFilename(url)
    },
    getFilename(fileUrl) {
      if (!fileUrl) return 'UNKNOWN'
      let url = decodeURIComponent(fileUrl)
      // 如果不是网络url,直接使用
      if (!url.includes('/')) return url
      // 截取url最后一个"/"后面"?"前面的
      url = url.trim().substring(url.lastIndexOf('/') + 1)
      if (url.includes('?')) {
        url = url.substring(0, url.indexOf('?'))
      }
      return url
    },
    handleOk() {
      this.$refs.cropper.getCropBlob(data => {
        // do something
        let file = new window.File([data], this.fileName, { type: this.option.outputType })
        this.upImg(file)
      })
    },
    async getBackGround() {
      try {
        const res = await getAction('/theme/query')
        if (res.msg == 'SUCCESSFUL') {
          res.data.forEach(item => {
            if (item.type == 'COLOR') {
              this.formData.colors = [...item.colors]
            } else if (item.type == 'BACKGROUND') {
              this.formData.userFile.avatar = item.value
              this.option.img = item.origin
              this.formData.opacity = item.opacity || 0
              this.formData.background = item.background || '#000000'
            } else if (item.type == 'LOGO') {
              this.formData.logo = item.value
            } else if (item.type == 'SLOGAN') {
              this.formData.slogan = item.value
            }
          })
          this.$forceUpdate()
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async save() {
      let data = [
        {
          type: 'BACKGROUND',
          value: this.formData.userFile.avatar,
          origin: this.option.img,
          opacity: this.formData.opacity,
          background: this.formData.background
        },
        {
          type: 'COLOR',
          colors: this.formData.colors
        },
        {
          type: 'LOGO',
          value: this.formData.logo
        },
        {
          type: 'SLOGAN',
          value: this.formData.slogan
        }
      ]
      try {
        const res = await putAction('/theme', data)
        if (res.msg == 'SUCCESSFUL') {
          this.$message.success('保存成功')
          this.getBackGround()
          window.location.reload()
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleClose(index) {
      this.$nextTick(() => {
        this.formData.colors.splice(index, 1)
      })
    },
    addColor() {
      let check = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
      check.test(this.color) && this.formData.colors.push({ value: this.color })
    },
    handleChangeUpload(info, type) {
      console.log(info, 3)
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        if (type == 'BACK') {
          this.formData.userFile.avatar = params.filePath
        } else {
          this.formData.logo = params.filePath
        }
        this.removeOssFilePolicy(info.file)
        this.avatarLoading = false
      }
    },
    handleUpChange({ file, fileList }) {
      return false
    },
    beforeCroUpload(file) {
      this.fileList = [...this.fileList, file]
      const url = window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      /* this.formData.userFile.avatar = url */
      this.openCropper(url)
      return false
    },
    openCropper(url) {
      this.openModal(url)
    },
    async upImg(file) {
      await this.beforeUpload(file)
      const token = Vue.ls.get(ACCESS_TOKEN)
      let formData = new FormData()
      this.ossUploadData.key = this.ossUploadData.key + '.' + file.type
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', file)
      axios
        .post(this.ossUploadUrl, formData, {
          'Content-Type': 'multipart/form-data;',
          headers: {
            'X-Access-Token': token
          }
        })
        .then(() => {
          file.status = 'done'
          this.handleChangeUpload({ file }, 'BACK')
        })
    }
  }
}
</script>

<style scoped lang="less">
.cropper-content {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  .cropper {
    width: 400px;
    height: 225px;
  }
  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    position: relative;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    margin-left: 40px;
    .preview {
      overflow: hidden;
      border: 1px solid #cccccc;
      background: #cccccc;
    }
  }
}
.opcity-div {
  position: absolute;
  top: 0;
  z-index: 2;
}
/deep/ .ant-form-item-children {
  display: flex;
  flex-wrap: wrap;
}
.img-div {
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 24px;
  transform: scale(0.5);
  position: absolute;
  top: 117px;
  left: -168px;
}
/deep/ .ant-upload.ant-upload-select-picture-card:hover {
  border-color: var(--theme-color) !important;
}
.m-colorPicker:hover {
  cursor: pointer;
}
.m-colorPicker {
  margin-top: 13px;
}
</style>
