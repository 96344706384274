var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "action" }, [
        _c("div"),
        _c(
          "div",
          [
            _vm.selectKeys.length
              ? _c(
                  "a-button",
                  {
                    staticClass: "mr-3",
                    attrs: { icon: "delete", type: "danger" },
                    on: { click: _vm.delePendant },
                  },
                  [_vm._v("删除")]
                )
              : _vm._e(),
            _c(
              "a-button",
              {
                attrs: { type: "primary", icon: "upload" },
                on: { click: _vm.addPendant },
              },
              [_vm._v("上传")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "pendant-list" },
        _vm._l(_vm.pendantList, function (item) {
          return _c(
            "div",
            {
              key: item.headwear.id,
              staticClass: "pendant",
              on: {
                click: function ($event) {
                  return _vm.editPendant(item)
                },
              },
            },
            [
              _c("a-checkbox", {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                  change: (e) => _vm.selectPendant(e, item),
                },
              }),
              _c("img", { attrs: { src: item.headwear.url, alt: "" } }),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "上传", visible: _vm.visible },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c("div", { staticClass: "pendant-modal" }, [
            _c("div", { staticClass: "pendant-left" }, [
              _c("div", [
                _vm.userInfo.userFile.avatar
                  ? _c("div", { staticClass: "img-div" }, [
                      _c("img", {
                        attrs: { src: _vm.userInfo.userFile.avatar, alt: "" },
                      }),
                    ])
                  : _vm._e(),
                _vm.pendantUrl
                  ? _c(
                      "div",
                      {
                        staticClass: "select-pendant",
                        style: `top:${_vm.top}px;left:${_vm.left}px;transform: rotate(${_vm.deg}deg);`,
                      },
                      [
                        _vm.pendantUrl
                          ? _c("img", {
                              attrs: { src: _vm.pendantUrl, alt: "" },
                            })
                          : _c("div"),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "pendant-right" }, [
              _c("div", [
                _c("div", { staticClass: "pendant-label" }, [_vm._v("挂件：")]),
                _c(
                  "div",
                  { staticClass: "pendant-item" },
                  [
                    _c(
                      "a-upload",
                      {
                        staticClass: "avatar-uploader",
                        attrs: {
                          name: "file",
                          "show-upload-list": false,
                          action: _vm.ossUploadUrl,
                          data: _vm.ossUploadData,
                          beforeUpload: _vm.beforeUpload,
                        },
                        on: {
                          change: (info) => {
                            _vm.handleChangeUpload(info, "cover")
                          },
                        },
                      },
                      [
                        _c(
                          "a-button",
                          [
                            _c("a-icon", { attrs: { type: "upload" } }),
                            _vm._v(" 点击上传 "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("div", { staticClass: "pendant-label" }, [_vm._v("横向：")]),
                _c(
                  "div",
                  { staticClass: "pendant-item" },
                  [
                    _c("a-input-number", {
                      attrs: { max: 100, min: -100 },
                      model: {
                        value: _vm.left,
                        callback: function ($$v) {
                          _vm.left = $$v
                        },
                        expression: "left",
                      },
                    }),
                    _vm._v(" PX"),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("div", { staticClass: "pendant-label" }, [_vm._v("竖向：")]),
                _c(
                  "div",
                  { staticClass: "pendant-item" },
                  [
                    _c("a-input-number", {
                      attrs: { max: 100, min: -100 },
                      model: {
                        value: _vm.top,
                        callback: function ($$v) {
                          _vm.top = $$v
                        },
                        expression: "top",
                      },
                    }),
                    _vm._v(" PX"),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("div", { staticClass: "pendant-label" }, [_vm._v("旋转：")]),
                _c(
                  "div",
                  { staticClass: "pendant-item" },
                  [
                    _c("a-input-number", {
                      model: {
                        value: _vm.deg,
                        callback: function ($$v) {
                          _vm.deg = $$v
                        },
                        expression: "deg",
                      },
                    }),
                    _vm._v(" DEG"),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }