<template>
  <div>
    <div class="action">
      <div></div>
      <div>
        <a-button class="mr-3" icon="delete" type="danger" v-if="selectKeys.length" @click="delePendant">删除</a-button>
        <a-button type="primary" icon="upload" @click="addPendant">上传</a-button>
      </div>
    </div>
    <div class="pendant-list">
      <div class="pendant" v-for="item in pendantList" :key="item.headwear.id" @click="editPendant(item)">
        <a-checkbox @click.stop @change="e => selectPendant(e, item)" />
        <img :src="item.headwear.url" alt="" />
      </div>
    </div>
    <a-modal title="上传" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <div class="pendant-modal">
        <div class="pendant-left">
          <div>
            <div v-if="userInfo.userFile.avatar" class="img-div">
              <img :src="userInfo.userFile.avatar" alt="" />
            </div>
            <div
              v-if="pendantUrl"
              class="select-pendant"
              :style="`top:${top}px;left:${left}px;transform: rotate(${deg}deg);`"
            >
              <img v-if="pendantUrl" :src="pendantUrl" alt="" />
              <div v-else></div>
            </div>
          </div>
        </div>
        <div class="pendant-right">
          <div>
            <div class="pendant-label">挂件：</div>
            <div class="pendant-item">
              <a-upload
                name="file"
                class="avatar-uploader"
                :show-upload-list="false"
                :action="ossUploadUrl"
                :data="ossUploadData"
                :beforeUpload="beforeUpload"
                @change="
                  info => {
                    handleChangeUpload(info, 'cover')
                  }
                "
              >
                <a-button> <a-icon type="upload" /> 点击上传 </a-button>
              </a-upload>
            </div>
          </div>
          <div>
            <div class="pendant-label">横向：</div>
            <div class="pendant-item"><a-input-number :max="100" :min="-100" v-model="left" /> PX</div>
          </div>
          <div>
            <div class="pendant-label">竖向：</div>
            <div class="pendant-item"><a-input-number :max="100" :min="-100" v-model="top" /> PX</div>
          </div>
          <div>
            <div class="pendant-label">旋转：</div>
            <div class="pendant-item"><a-input-number v-model="deg" /> DEG</div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import { postAction, getAction, putAction, deleteAction } from '@/api/manage'
export default {
  mixins: [UploadFileToOssMixins],
  data() {
    return {
      visible: false,
      pendantUrl: '',
      userInfo: {
        userFile: {
          avatar: ''
        }
      },
      selectKeys: [],
      pendantList: [],
      top: 0,
      left: 0,
      deg: 0,
      avatarLoading: false,
      color: '',
      isEdit: false,
      imgId:''
    }
  },
  created() {
    this.userInfo = Vue.ls.get(USER_INFO)
    this.getPendantList()
  },
  mounted() {
    this.color = this.$store.state.app.color
  },
  methods: {
    async getPendantList() {
      let params={
        size:9999,
        current:1,
      }
      const res = await getAction('/headwear/page/admin',params)
      if (res.code == 200) {
        this.pendantList = res.data.records
      } else {
        this.$message.error(res.msg)
      }
    },
    async delePendant() {
      let params = {ids:this.selectKeys.join(',')}
      const res = await deleteAction('/headwear',params)
      if(res.code==200){
        this.$message.success('删除成功')
        this.getPendantList()
      }else{
        this.$message.error(res.msg||res.message)
      }
    },
    selectPendant(e, item) {
      if (e.target.checked) {
        this.selectKeys.push(item.headwear.id)
      } else {
        this.selectKeys.splice(
          this.selectKeys.findIndex(i => {
            return i == item.headwear.id
          }),
          1
        )
      }
    },
    handleChangeUpload(info, type) {
      console.log(info, 3)
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        this.pendantUrl = params.filePath
        this.removeOssFilePolicy(info.file)
        this.avatarLoading = false
      }
    },
    addPendant() {
      this.visible = true
      this.isEdit = false
    },
    editPendant(item) {
      this.isEdit = true
      this.top=item.headwear.top
      this.left=item.headwear.left
      this.pendantUrl=item.headwear.url
      this.deg=item.headwear.deg
      this.imgId=item.headwear.id
      this.visible = true
    },
    async handleOk() {
      let data = {
        url: this.pendantUrl,
        top: this.top,
        left: this.left,
        deg: this.deg,
        viewable: true
      }
      if (this.isEdit) {
        data.id = this.imgId
        const res = await putAction('/headwear', data)
        if (res.code == 200) {
          this.$message.success('上传成功')
          this.visible = false
          this.getPendantList()
          this.initData()
        } else {
          this.$message.error(res.msg || res.message)
        }
      } else {
        const res = await postAction('/headwear', data)
        if (res.code == 200) {
          this.$message.success('上传成功')
          this.visible = false
          this.getPendantList()
          this.initData()
        } else {
          this.$message.error(res.msg || res.message)
        }
      }
    },
    initData() {
      this.pendantUrl = ''
      this.deg = 0
      this.top = 0
      this.left = 0
    },
    handleCancel() {
      this.visible = false
      this.initData()
    }
  }
}
</script>

<style lang="less" scoped>
.img-div {
  width: 95px;
  height: 95px;
  border-radius: 50% !important;
  overflow: hidden;
  position: relative;
  img {
    width: 95px;
    height: auto;
    left: 0;
    position: absolute;
  }
}
.select-pendant {
  position: absolute;
  width: 35px;
  height: 35px;
  text-align: center;
  z-index: 1;
  img {
    width: 80%;
    height: auto;
    transform: rotate(0deg);
  }
  > div {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
  }
}
.action {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}
.pendant-modal {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  > div {
    width: 50%;
  }
  .pendant-left {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      width: 95px;
      position: relative;
    }
  }
  .pendant-right {
    > div {
      display: flex;
      justify-content: space-between;
      padding-top: 15px;
      > div {
        width: 70%;
      }
      .pendant-label {
        width: 30%;
      }
      .pendant-item {
        position: relative;
        top: -5px;
      }
      .ant-input-number {
        width: 100px;
        margin-right: 10px;
      }
    }
  }
}
.pendant-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
  max-height: calc(100vh - 305px);
  overflow-y: auto;
  .pendant {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-left: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    cursor: pointer;
    .ant-checkbox-wrapper {
      position: absolute;
      top: 2px;
      right: 2px;
    }
    img {
      width: 80%;
      height: auto;
    }
  }
}
</style>
