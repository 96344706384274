<template>
  <a-modal v-model="open" width="950px" :title="title" @cancel="cancel" @ok="handleOk">
    <div class="cropper-content">
      <div class="cropper">
        <vue-cropper
          ref="cropper"
          mode="100% auto"
          :fixed="true"
          :centerBox="true"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="true"
          :full="option.full"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixedNumber="[16, 9]"
          @realTime="realTime"
        ></vue-cropper>
      </div>
      
      <div class="show-preview" :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden', 'margin': '5px'}">
        <div :style="{...previews.div}" class="preview">
          <img :src="previews.url" :style="previews.img">
        </div>
      </div>
    </div>

    <div class="footer-btn">
      <div class="scope-btn">
        <!-- <label class="btn" for="uploads">更换图片</label> -->
        <input
          type="file"
          id="uploads"
          style="position:absolute; clip:rect(0 0 0 0);"
          accept="image/png, image/jpeg, image/gif, image/jpg"
          @change="uploadImg($event, 1)"
        />

        <a-button shape="circle" icon="plus" @click="changeScale(1)"></a-button>
        <a-button shape="circle" icon="minus" @click="changeScale(-1)"></a-button>
        <a-button shape="circle" icon="undo" @click="rotateLeft"></a-button>
        <a-button shape="circle" icon="redo" @click="rotateRight"></a-button>
      </div>
      <!-- <div class="upload-btn">
         <button>下载</button>
      </div> -->
    </div>
  </a-modal>
</template>

<script>
import { VueCropper } from 'vue-cropper'
export default {
  props: {
    title: {
      type: String,
      defualt: '裁剪'
    }
  },
  components: {
    VueCropper
  },
  data() {
    return {
      open: false,
      imageUrl: '',
      crap: false,
      previews: {},
      option: {
        img: '',
        size: 1,
        full: true, //输出原图比例截图 props名ful
        outputType: 'png',
        canMove: true,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 400,
        autoCropHeight: 225,
        fixedBox: false,
      },
      downImg: '#',
      fileName: ''
    }
  },
  computed: {},
  created() {},
  methods: {
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    finish(type) {
      var test = window.open('about:blank')
      test.document.body.innerHTML = '图片生成中..'
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob(data => {
          var img = window.URL.createObjectURL(data)
          this.model = true
          this.modelSrc = img
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          this.model = true
          this.modelSrc = data
        })
      }
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data
    },
    openModal(url) {
      this.open = true
      this.option.img = url
      this.fileName = this.getFilename(url)
    },
    getFilename(fileUrl) {
      if (!fileUrl) return 'UNKNOWN'
      let url = decodeURIComponent(fileUrl)
      // 如果不是网络url,直接使用
      if (!url.includes('/')) return url
      // 截取url最后一个"/"后面"?"前面的
      url = url.trim().substring(url.lastIndexOf('/') + 1)
      if (url.includes('?')) {
        url = url.substring(0, url.indexOf('?'))
      }
      return url
    },
    handleOk() {
      this.$refs.cropper.getCropBlob(data => {
        // do something
        let file = new window.File([data], this.fileName, { type: this.option.outputType })
        this.$emit('upImg', file)
        this.open = false
      })
    },
    cancel() {}
  }
}
</script>

<style lang="less" scoped>
.cropper-content {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  .cropper {
    width: 400px;
    height: 225px;
  }
  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    .preview {
      overflow: hidden;
      border: 1px solid #cccccc;
      background: #cccccc;
      margin-left: 40px;
    }
  }
}
.footer-btn {
  margin-top: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  .scope-btn {
    width: 200px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }
  .upload-btn {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
  }
  .btn {
    outline: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #67c23a;
    border-color: #67c23a;
  }
}
</style>
