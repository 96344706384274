var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { style: _vm.css, attrs: { bordered: false } },
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" }, on: { change: _vm.tabChange } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: " 登录背景设置" } },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "form",
                    staticStyle: {
                      position: "relative",
                      "padding-top": "20px",
                    },
                    attrs: { model: _vm.formData },
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "主题颜色", prop: "color" } },
                    [
                      _c("colorPicker", {
                        on: { change: _vm.addColor },
                        model: {
                          value: _vm.color,
                          callback: function ($$v) {
                            _vm.color = $$v
                          },
                          expression: "color",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-left": "80px",
                        "margin-bottom": "5px",
                        "margin-top": "-10px",
                        width: "650px",
                      },
                    },
                    _vm._l(_vm.formData.colors, function (color, index) {
                      return _c(
                        "a-tag",
                        {
                          key: index,
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { color: color.value, closable: "" },
                          on: {
                            close: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.handleClose(index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(color.value))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "遮罩颜色", prop: "backColor" } },
                    [
                      _c("colorPicker", {
                        model: {
                          value: _vm.formData.background,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "background", $$v)
                          },
                          expression: "formData.background",
                        },
                      }),
                      _c("a-slider", {
                        staticStyle: { width: "200px", "margin-left": "20px" },
                        attrs: { id: "test" },
                        model: {
                          value: _vm.formData.opacity,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "opacity", $$v)
                          },
                          expression: "formData.opacity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "背景图片", prop: "color" } },
                    [
                      !_vm.formData.userFile.avatar
                        ? _c(
                            "a-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                name: "file",
                                "list-type": "picture-card",
                                "show-upload-list": false,
                                beforeUpload: _vm.beforeCroUpload,
                              },
                              on: { change: _vm.handleUpChange },
                            },
                            [
                              _vm.formData.userFile.avatar
                                ? _c(
                                    "div",
                                    {
                                      style:
                                        _vm.imgStyle +
                                        "background: url(" +
                                        _vm.formData.userFile.avatar +
                                        ") bottom / cover no-repeat;position: relative;",
                                      attrs: {
                                        src: _vm.formData.userFile.avatar,
                                        alt: "avatar",
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "opcity-div",
                                        style:
                                          _vm.imgStyle +
                                          "opacity:" +
                                          _vm.formData.opacity / 100 +
                                          ";background-color:" +
                                          _vm.formData.background,
                                      }),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("a-icon", {
                                        attrs: {
                                          type: _vm.avatarLoading
                                            ? "loading"
                                            : "plus",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "ant-upload-text" },
                                        [_vm._v("Upload")]
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          )
                        : _c(
                            "a-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                name: "file",
                                "show-upload-list": false,
                                beforeUpload: _vm.beforeCroUpload,
                              },
                              on: { change: _vm.handleUpChange },
                            },
                            [
                              _c(
                                "a-button",
                                { staticStyle: { "z-index": "0" } },
                                [
                                  _c("a-icon", { attrs: { type: "upload" } }),
                                  _vm._v(" 上传图片 "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                      _vm.formData.userFile.avatar
                        ? _c("div", { staticClass: "cropper-content" }, [
                            _c(
                              "div",
                              { staticClass: "cropper" },
                              [
                                _c("vue-cropper", {
                                  ref: "cropper",
                                  attrs: {
                                    mode: "100% auto",
                                    fixed: true,
                                    centerBox: true,
                                    img: _vm.option.img,
                                    outputSize: _vm.option.size,
                                    outputType: _vm.option.outputType,
                                    info: true,
                                    full: _vm.option.full,
                                    canMove: _vm.option.canMove,
                                    canMoveBox: _vm.option.canMoveBox,
                                    original: _vm.option.original,
                                    autoCrop: _vm.option.autoCrop,
                                    autoCropWidth: _vm.option.autoCropWidth,
                                    autoCropHeight: _vm.option.autoCropHeight,
                                    fixedNumber: [16, 9],
                                  },
                                  on: { realTime: _vm.realTime },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "show-preview",
                                style: {
                                  width: _vm.previews.w + "px",
                                  height: _vm.previews.h + "px",
                                  overflow: "hidden",
                                  margin: "5px",
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "opcity-div",
                                  style:
                                    _vm.imgStyle +
                                    "opacity:" +
                                    _vm.formData.opacity / 100 +
                                    ";background-color:" +
                                    _vm.formData.background,
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview",
                                    style: { ..._vm.previews.div },
                                  },
                                  [
                                    _c("img", {
                                      style: _vm.previews.img,
                                      attrs: { src: _vm.previews.url },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "logo", prop: "color" } },
                    [
                      _c(
                        "a-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            name: "file",
                            "list-type": "picture-card",
                            "show-upload-list": false,
                            action: _vm.ossUploadUrl,
                            data: _vm.ossUploadData,
                            beforeUpload: _vm.beforeUpload,
                          },
                          on: {
                            change: (info) => {
                              _vm.handleChangeUpload(info, "LOGO")
                            },
                          },
                        },
                        [
                          _vm.formData.logo
                            ? _c("img", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  src: _vm.formData.logo,
                                  alt: "avatar",
                                },
                              })
                            : _c(
                                "div",
                                [
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.avatarLoading
                                        ? "loading"
                                        : "plus",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "ant-upload-text" },
                                    [_vm._v("Upload")]
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "slogan", prop: "color" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入slogan" },
                        model: {
                          value: _vm.formData.slogan,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "slogan", $$v)
                          },
                          expression: "formData.slogan",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { "wrapper-col": { span: 12, offset: 0 } } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "80px" },
                          attrs: { icon: "save", type: "primary" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: " 挂件管理" } },
            [_c("pendant")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: " 首页顶部背景管理" } },
            [_c("default-size-upload")],
            1
          ),
        ],
        1
      ),
      _c("cropper-model", { ref: "cropperModel", on: { upImg: _vm.upImg } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }